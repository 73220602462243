import React from 'react';
import './index.css';

const productList = [
  { href: '#', img: '/images/product.01.png', title: '30pcs Tracheostomy Trach Vent HME Filter', desc: '', price: '$20.75/lot' },
  { href: '#', img: '/images/product.02.png', title: '2pcs Disposable Steriled Heat Moisture Exchangers Tracheostomy Trach Filter', desc: '', price: '$0.99' },
  { href: '#', img: '/images/product.03.png', title: 'Tracheal cannula shield tracheostomy tube wind cap dust cover', desc: '', price: '$2.23' },
  { href: '#', img: '/images/product.04.png', title: 'Medical Tracheal catheter Ultra-soft fixation tracheotomy tube strap', desc: '', price: '$2.57' },
  { href: '#', img: '/images/product.05.png', title: 'Tracheostomy cannula Brushes trach tube Brush', desc: '', price: '$1.72/lot' },
  { href: '#', img: '/images/product.06.png', title: '10 Bags Tracheal Cannula Medical Tracheostomy Gauze Tracheo Drain Swabs', desc: '', price: '$2.86/lot' },
]

const ProductItem = ({ href = '#', img, title = '', desc = '', price = '' }) => {
  return (
    <div className="product-item">
      <a href={href} className='product-link'>
        <div className="product-img">
          <img src={img} alt="img" />
        </div>
        <h3 className="product-title">{title}</h3>
        <p className="product-desc">{desc}</p>
        <p className="product-price">{price}</p>
      </a>
    </div>
  )
}

const Home = () => {
  return (
    <div className='layout'>
      <div className="container">
        <div className="product-list">
          {
            productList.map((item, key) => {
              return <ProductItem href={item.href} img={item.img} title={item.title} price={item.price} key={key} />
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Home;
