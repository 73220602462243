import React from 'react';
import './index.css';

const Footer = () => {
  return (
    <div className='footer'>
      <div className="container">
        <p>
        Feit Medical © 2024 / All Rights Reserved.
        </p>
      </div>
    </div>
  )
}

export default Footer;