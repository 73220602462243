import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import Header from './views/layout/header';
import Footer from './views/layout/footer';


function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
