import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../views/home';
import Product from '../views/product';

const Router = () => {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
    </Routes>
  )
}

export default Router;
