import React from 'react';
import './index.css';

const Header = () => {
  return (
    <div className='header'>
      <div className='container'>
        <div className="nav">
          <div className="wrapper logo_wrapper">
            <a href="/" className="logo">
              <img src="/logo.png" alt="FEITMED" />
              <i>Feit Medical</i>
            </a>
          </div>
        </div>
        <div className="nav">
          <div className="wrapper">
            <a href="mailto:feitmed@outlook.com" className='mail_wrapper'>
              <img src="/images/email.png" alt='mail' />
              <i>feitmed@outlook.com</i>
            </a>
          </div>
          <div className="wrapper">
            <a href='https://www.google.com/maps/place/Hangzhou,+Zhejiang,+China/' target='_blank' rel="noreferrer" className='map_wrapper'>
              <img src="/images/map.png" alt='map' />
              <i>hangzhou, China</i>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;
